import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Profile from "../components/profile"

import Seo from "../components/seo"

const IndexPage = ({ data, location, pageContext }) => {
  return (
    <Layout>
      <Seo pagepath={location.pathname} />
      <div className="wrapper">
        <div className="featured">
          <h2 className="featured__title">人気記事</h2>
        </div>
        <p className="featured__picture"><Link to={"/why-hsp-should-be-a-translator/"} className><StaticImage src="../images/f1.jpg" alt="HSPが翻訳家に向いている理由" quality={70} layout="fullWidth" placeholder="dominantColor" className="featured__image" /></Link></p>
        <p className="featured__picture"><Link to={"/toeic-scores-required-for-translators/"} className><StaticImage src="../images/f2.jpg" alt="翻訳家になるのにTOEICは何点必要？" quality={70} layout="fullWidth" placeholder="dominantColor" className="featured__image" /></Link></p>
        <p className="featured__picture"><Link to={"/how-to-select-toeic-vocabulary-books/"} className><StaticImage src="../images/f3.jpg" alt="TOEIC単語帳の選び方" quality={70} layout="fullWidth" placeholder="dominantColor" className="featured__image" /></Link></p>
        <p className="featured__picture"><Link to={"/speaking-skills-for-translators/"} className><StaticImage src="../images/f4.jpg" alt="翻訳家にスピーキングは必要？" quality={70} layout="fullWidth" placeholder="dominantColor" className="featured__image" /></Link></p>
        <p className="ad"><a href="https://px.a8.net/svt/ejp?a8mat=3N879D+354DPU+8FM+691UP" rel="nofollow">
          <img alt="" src="https://www25.a8.net/svt/bgt?aid=220412353190&wid=001&eno=01&mid=s00000001093001050000&mc=1" width={728} height={90} border={0} /></a>
          <img src="https://www14.a8.net/0.gif?a8mat=3N879D+354DPU+8FM+691UP" alt="" width={1} height={1} border={0} /></p>
        <p className="ad2"><a href="https://t.afi-b.com/visit.php?guid=ON&a=x10922k-q370537F&p=X8539585" rel="nofollow"><img src="https://www.afi-b.com/upload_image/10922-1567164731-3.jpg" style={{border: 'none'}} alt="映像翻訳Web講座 ベーシックコース" width={300} height={250} /></a><img src="https://t.afi-b.com/lead/x10922k/X8539585/q370537F" style={{border: 'none'}} width={1} height={1} alt=""/></p>
        <p className="ad3"><a href="https://px.a8.net/svt/ejp?a8mat=3N8816+5H2I0I+B8G+6L1Y9" rel="nofollow">
          <img alt="" src="https://www20.a8.net/svt/bgt?aid=220413354331&wid=001&eno=01&mid=s00000001456001106000&mc=1" width={300} height={250} border={0} /></a>
          <img src="https://www11.a8.net/0.gif?a8mat=3N8816+5H2I0I+B8G+6L1Y9" alt="" width={1} height={1} border={0} /></p>
        <div className="latest">
          <h2 className="latest__title">新着記事</h2>
        </div>

        {data.allContentfulBlogPost.edges.map(({ node }) => (
          <article className="post" key={node.id}>
            <Link to={`/${node.slug}/`} className="post__link">
              <p className="post__picture"><GatsbyImage image={node.eyecatch.gatsbyImageData} alt={node.eyecatch.description} className="post__image" /></p>
              <h3 className="post__title">{node.title}</h3>
              <ul class="post__category">
                {node.category.map(cat => (
                  <li className="post__categoryItem" key={cat.id}>{cat.category}</li>
                ))}
              </ul>
              <p className="post__date"><time dateTime={node.publishDate}>{node.publishDateJP}</time></p>
            </Link>
          </article>
        ))}

        {!pageContext.isFirst && (
          <div className="previous">
            <Link to={pageContext.currentPage === 2
              ? `/`
              : `/${pageContext.currentPage - 1}/`} className="previous__link" rel="prev">
              <div className="previous__arrow">
              </div>
              <p className="previous__text">前のページ</p>
            </Link>
          </div>
        )}

        {!pageContext.isLast && (
          <div className="next">
            <Link to={`/${pageContext.currentPage + 1}/`} className="next__link" rel="next">
              <p className="next__text">次のページ</p>
              <div className="next__arrow">
              </div>
            </Link>
          </div>
        )}

        <Profile />
      </div>

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
      ) {
      edges {
        node {
          title
          publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
          publishDate
          id
          slug
          eyecatch {
            gatsbyImageData(layout: CONSTRAINED)
            description
          }
          category {
            category
            categorySlug
            id
        }
        }
      }
    }
  }
`